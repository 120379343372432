.save-btn {
    position: absolute;
    left: 50%;
    top: 35%;
}

.check-journery {

    .ant-checkbox-inner,
    :where(.css-dev-only-do-not-override-1jr9qlj).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
        border-color: green;
    }

    .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {

        background-color: green;
        border-color: green;
    }
}

.select-loan {
    border: 1px solid gray;
    border-radius: 6px;
}

.rule-space {
    .ant-space-item {
        width: 130px;
    }
}

.vas-col {
    padding: 0 25px;
}

.vas-form {
    .check-journery {
        margin-bottom:0px !important
    }
}

.margin-rule {
    margin-left: 20px;
    margin-bottom: 0 !important;
}

.input-loan {
    width: 50%;
    border: 1px solid gray;
    border-radius: 6px;
}

.decision-stage {
    position: relative;
    top: 25%;
}