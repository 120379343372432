.card-Preview {
    .ant-card-body {
        padding: 0px;
    }
}

.disabled-text {
    .ant-input[disabled] {
        color: #222222d2 !important;
        background-color: #f0f0f0 !important;
    }
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background-color: #c71c8e !important;
    border-color: #c71c8e !important;
}
.form-offer-screen {
    padding: 5px;
    width: 65%;
    .formio-form {
        min-height: auto;
        margin-top: 5px;
    }
}
.radio {
    .field-label {
        width: 206px;
    }
    display: flex !important;
    .form-check-input:checked {
        background-color: #c71c8e !important;
        border-color: #c71c8e !important;
    }
    .form-check {
        padding: 0px;
    }
    .form-check-label {
        display: flex;
        padding: 5px;
        align-items: center;
        span {
            margin: 5px;
        }
        .form-check-input {
            margin: 5px;
        }
    }
}
.form-thank-box {
    padding: 5px;
    margin: auto -5px -5px -5px;
    .field-wrapper {
        width: 100%;
    }
    textarea {
        width: 65% !important;
    }
}
.fa-question-circle:before {
    content: url(../../../../assets/images/info.svg) !important;
}
