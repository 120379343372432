.ant-form-item-has-error {
    padding-bottom: 12px !important;
}

.field-border {
    border-color: rgba(0, 0, 0, 0.19) !important;
}

.field-bg {
    margin-top: 10px;
    padding: 5px 10px;
    background: #FFFFFF;
    border-radius: 10px;
    border: solid 1px #f7f7f7;
    margin-bottom: 10px;

    .ant-form-item-label {
        // background-color: #FFFFFF;
        margin-top: -16px;
        margin-left: 5px;
        padding: 0px;
    }

    .ant-form-item-label>label {
        background-color: #FFFFFF;
        padding: 0px 4px;
    }

    .ant-form-item-control-input {
        height: 40px !important;
    }

}

.anpunt-it:placeholder-shown {
    border: hidden;
}

.ant-input {
    border: hidden;
}

.ant-select:placeholder-shown {
    border: hidden;
}

.ant-select-selector {
    border: hidden !important;
}

.ant-input-number {
    width: 100% !important;
    border: hidden !important;
}

.ant-input-number:hover .ant-input-number-handler-wrap {
    opacity: 0;
}