.deleteButton {
    background: #F3F6F9;
    color: black !important;
    border: none !important;
}

.admin {
    background-color: #C71C8E;
    color: white;
    height: 22px;
    width: 53px;
    text-align: center;
    border-radius: 10px;
}

.step-button {
    display: flex;
    justify-content: start;
    margin-top: 20px;
    &.end {
        justify-content: end;
    }
    width: 100%;
}

.select-content {
    padding: 10px;
    display: flex;
    align-items: center;
}

.search-and-btn {
    display: flex;
    float: right;

    @media(max-width: 650px) {
        display: grid;
    }
}

.search-box {
    width: 100% !important;
    border-radius: 12px !important;
    height: 42px;
    margin-right: 15px;

    @media(max-width: 650px) {
        margin-bottom: 15px;
    }
}

.collection-btn {
    float: right;
    margin-right: 10px;
    width: min-content !important;
}

.step-submit-button {
    display: flex;
    justify-content: end;
}

.ant-alert-message {
    font-weight: bold;
}
.ant-alert{
    top: 15% !important;
    width: 30% !important;
}

.select-list {
    margin-top: 10px;
    padding: 5px 10px;
    background: #FFFFFF;
    border-radius: 10px;
    border: solid 1px #dbdbdb;

    .ant-form-item {
        height: 0px;
    }

    .ant-form-item-label {
        margin-top: -16px;
        margin-left: 5px;
    }

    .ant-form-vertical .ant-form-item-label>label {
        background-color: #FFFFFF;
    }
}

.preview-box {
    width: 100%;
    margin-top: 20px;
    height: 70px;
    padding: 0px 4px;
    border: 2px;
    border-radius: 10px;
    border: solid 1px;
    border-color: rgba(0, 0, 0, 0.19) !important;

    .preview-name-div {
        padding: -1px 4px;
        margin-left: 10px;
        background-color: #ffffff;
        margin-top: -12px;
        width: 80px;
    }

    .preview-marging {
        margin-top: 6px;
        margin-left: 10px;
    }

    .preview-lable {
        margin-left: 10px;
        text-transform: capitalize;
    }

    .text-color {
        color: gray;
    }
}

.drawer-layout {
    border-top: 1px solid #DFE0EB;

    .drawer-form {
        padding: 25px;
        max-width: 365px;
        min-width: 260px;
        margin-top: 1%;
    }
}
.search-input  {
    margin-top: 2px;
    border: none;
    border-radius: unset;
    margin-bottom: 5px;
}
.backToProperty{
    width: 150px;
    float: right;
    margin-bottom: 1%;
}
.preview-name-div1 label {
    position: relative;
     
}
.preview-name-div1 label::before {
    
     
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
}
.addColForPropetyList{
    float: right;
    margin-top: 1%;
    padding-right: 28%;
}
.addPropetyBorder{
    border: 1px solid #ddd;
    margin-right: 20px;
}
.headingProperty{
    border-bottom: 1px solid #ddd;
    padding: 11px;
}
.propertydivHeight{
    height: 200px;
    overflow: auto;
}
.propertyListLI{
    padding-bottom: 6px;
}
.ant-tooltip .ant-tooltip-inner{
    background-color: rgb(37 63 135) !important;
}

@media(max-width: 650px) {

    .addColForPropetyList{
        width: 100% !important;
    }
    .addcolForCreateObject{
        width: 100% !important;
    }
}
