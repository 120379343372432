.ant-tabs-nav .ant-tabs-ink-bar {
  background-color: #273896 !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #273896 !important;
  font-weight: 500;
}

// .ant-table-thead>tr>th {
//   background: transparent !important;
// }

.ant-table-cell {
  padding: 8px !important;
}

.ant-switch.ant-switch-checked {
  background: #20A74E;

  &:hover {
    background: #20A74E !important;
  }
}
