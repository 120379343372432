.login-card {
    position:absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 300px;
    height: 400px;
}

.space-input {
    margin-top: 20px;
}

.logo-style {
    display: flex;
    justify-content: center;
}