.pro-jou-tab {
    .ant-tabs-nav-wrap {
        padding: 0 10px;
        background-color: #EBEEF3;
    }
}

.Json-journey-tab {
    .ant-tabs-nav-wrap {
        background-color: transparent !important;
    }
}

.add-select {
    background-color: #EBEEF3;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.pro-jou-tab12 {
    .ant-tabs-nav-wrap {
        background-color: white !important;
        padding: 0 10px;
    }
}

.bg-Action {
    background: #DFE0EB3D;
    padding: 10px;
    margin-top: 10px;
    border-radius: 4px;
}
.bg-Action-no-action {
    background: #ffffff;
    padding: 10px;
    margin-top: 10px;
    border-radius: 4px;
}

.select-run-type {
    width: 150px;
    margin-block: 0px;
}

.action-names{
    font-size: 16px;
    font-weight: bold;
}

.number-point{
    height: 25px;
    width: 25px;
    border:1px solid #273896;
    font-weight: bold;
    color: #273896;
    text-align: center;
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block;
}

.divider:last-child {
    display: none;
}