.condition-title {
    padding: 10px 20px 0px;
}

.scorecard-tabs {
    .ant-tabs-tab {
        padding-inline: 30px !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
        background: #273896;

        .tabActive {
            color: white !important;
            font-weight: 350 !important;
        }
    }
}

.add-btns {
    padding: 10px 15px 0px 0px;
    display: flex;
    gap: 10px;
    height: 61px;
}

.columns-edit {
    float: right;
    margin-top: -8px;
}

.btns-tables {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    float: right;
    // margin-top: -45px;
    padding-right: 15px;
}

.fields-width {
    // .ant-space-item {
    width: -webkit-fill-available !important;
    // }
}

.vertical-center {
    position: relative;
    top: 50%;
    transform: translateY(-35%);
}

.decisioning-form {
    padding: 15px;
    max-width: 365px;
    min-width: 260px;
}

// .ant-table-thead {
//     tr {
//         word-break: break-all;
//     }
// }

//test decisioningform

.btn-side-by-side {
    .fieldset-body {
        display: flex;
        gap: 10px;
    }
}

.action_close {
    width: 100px;
    margin-top: 10px;
    background: transparent !important;
    color: #C71C8E !important;
    border-color: #C71C8E !important;

    :hover {
        background: transparent;
        color: #C71C8E;
        border-color: #C71C8E;
    }
}

.action_submit {
    width: 100px;
    margin-top: 10px;
}

.action_cancel {
    width: 100px;
    margin-top: 10px;
    background: transparent;
    color: #C71C8E;
    border-color: #C71C8E;

    :hover {
        background: transparent;
        color: #C71C8E;
        border-color: #C71C8E;
    }
}

.tested-data {
    display: flex;
    justify-content: space-between;

    span {
        width: 110px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        font-weight: bold;
    }
}

.list-space {
    .ant-space-item:nth-child(1) {
        position: sticky;
        left: 0;
        background-color: white;
        border-right: 1px solid #f0f0f0;
        z-index: 1;
    }

    .ant-space-item:last-child {
        margin-left: auto;
        height: 50px;
        align-content: center;
        position: sticky;
        right: 0;
        background-color: white;
        border-left: 1px solid #f0f0f0;
        z-index: 1;
    }
}

.col-list {
    .ant-space-item:first-child {
        position: sticky;
        left: 0;
        background-color: white;
        border-right: 1px solid #f0f0f0;
        z-index: 1;
    }
}

.list {
    --height: auto;
    background: #fafafa !important;
    .ant-space-item {
        background: #fafafa !important;
    }
    .ant-space-item:first-child {
        height: var(--height);
        position: sticky;
        left: 0px;
        z-index: 2;
        border-right: 1px solid #f0f0f0;
        margin-top: 8px;
    }

    .ant-space-item:last-child {
        margin-left: auto;
        height: var(--height);
        position: sticky;
        right: 0px;
        z-index: 2;
        border-left: 1px solid #f0f0f0;
    }

    .con-list {
        .ant-space-item {
            height: auto !important;
            min-height: auto !important;
            position: relative !important;
            background: transparent !important;
            z-index: 1;
            align-content: end;
            border: none !important;
        }

        .ant-space-item:last-child {
            margin-left: 0px !important;
            color: black !important;
            box-shadow: none !important;
            border: none !important;
        }
    }

    .act-list {
        .ant-space-item {
            height: auto !important;
            min-height: auto !important;
            position: relative !important;
            background: transparent !important;
            z-index: 1;
            border: none !important;
        }

        .ant-space-item:last-child {
            margin-left: 0px !important;
            color: black !important;
            box-shadow: none !important;
            border: none !important;
        }
    }
}