body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.upload-wrapper {
  .ant-upload-select {
    width: 100%;
    margin: 14px 0px;

    img {
      // margin-right: 10px;
      height: 30px;
      width: 25px;
    }

    span {
      display: flex;
      width: 100%;
      height: 90px;
      text-align: center;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      border: dashed 1px gray;
      border-radius: 8px;
      cursor: pointer;
    }
  }
  
  .ant-upload-list {
    .ant-upload-list-item {
        margin-bottom: 10px;
    }
}
}