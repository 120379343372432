.logo {
  text-align: center;
  border-bottom: none !important;
  margin: 20px 0px;
}

.nav-panel {
  width: 100%;
  padding-inline: 10px;
  padding-bottom: 20px;

  .ant-menu {
    border-inline-end: none !important;
    background: none !important;
    .ant-menu-submenu,
    .ant-menu-item {
      .side-icon-path-fill {
        path {
          fill: #9d9d9d;
        }
      }
      .side-icon-path-stroke {
        path {
          stroke: #9d9d9d;
        }
      }
      &.ant-menu-item-selected,
      &.ant-menu-item-active,
      &.ant-menu-submenu-active,
      &.ant-menu-submenu-selected {
        .side-icon-path-fill {
          path {
            fill: #ffffff;
          }
        }
        .side-icon-path-stroke {
          path {
            stroke: #ffffff;
          }
        }
      }
    }
    .ant-menu-submenu-active,
    .ant-menu-item-active {
      color: white !important;
      background-color: #6681cd !important;
    }
    .ant-menu-item-selected {
      color: white;
      background-color: #253f87;
    }
    .ant-menu-submenu-active {
      color: black !important;
      background-color: white !important;
      .ant-menu-submenu-title {
        color: white !important;
        background-color: #6681cd !important;
      }
    }
    .ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        color: white;
        background-color: #253f87;
      }
      .ant-menu-sub {
        background-color: white;
        .ant-menu-item-active,
        .ant-menu-item-selected {
          color: #253f87 !important;
          font-weight: bold;
          background-color: white !important;
        }
      }
    }
  }
}
