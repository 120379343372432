.ant-collapse-content-box {
    padding-block: 0px !important;
}

.line-vertical {
    height: 100%px;
    width: 1px;
    background-color: gray;
}
.child-view {
    display: flex;
    align-items: center;
    .line-horizontal {
        width: 15px;
        height: 1px;
        background-color: gray;
        // margin-top: 10px;
        margin-right: 5px;
    }
}