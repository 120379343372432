.preview-btn {
    background-color: #253f87
}

.json-modal {
    .ant-modal-body {
        height: 65vh !important;
        overflow: auto !important;
        overflow-x: hidden !important;
        width: 100% !important;
    }
}

.ellipsis-button {
    border: none !important;
    padding: 0;
}

.iconList-box {
    display: flex;
    justify-content: center;
    background: #EBECEE;
    border: 1px solid #d5d5d5;
    border-radius: 0px 0px 5px 5px;
    height: 40px;
}

.upload-wrapper {
    .ant-upload-select {
        width: 100%;
        height: 100px;
        margin: 14px 0px;

        img {
            margin-right: 10px;
            height: 30px;
            width: 25px;
        }

        span {
            display: flex;
            width: 100%;
            height: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;
            color: #273896;
            font-size: 16px;
            border: dashed 1px #273896;
            background: rgba(39, 56, 150, 0.13);
            cursor: pointer;
        }
    }

    .ant-upload-list {
        padding-bottom: 15px;

        .ant-upload-list-item {
            margin-bottom: 10px;
        }
    }
}