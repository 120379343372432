.repleaceText {
    text-align: center;
    font-weight: 700;
    color: #18ACFE;
    font-size: medium;
    cursor: pointer;
    padding-top: 10px;
}

.logoManage {
    .ant-divider-vertical {
        border-inline-start: 2px solid #18ACFE !important;
        margin-top: 15px;
        height: 22px;
    }

    display: flex;
    justify-content: center;
}

.upload-logo {
    height: 80% !important;
    width: 80% !important;
    object-fit: contain;
    margin-top: 9px;
}

.replace-background {
    // background: #273896;
    border: 1px dashed #273896;
    height: 130px;
    margin-bottom: 18px;
    border-radius: 10px;
}

.before-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    width: 70%;
    min-height: 55px;
    max-height: 75px;
    object-fit: contain;
}

.background-logo {
    width: 100%;
    height: 100%;
    // background: #273896;
    // border: 1px dotted #273896;
}

.upload-wrapper-bg-white {
    .ant-upload-select {
        span {
            background: white !important;
        }
    }
}