.app-header {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  min-height: 70px;
  max-height: 100%;
  width: 100%;

  .header {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    min-height: 46px;
    justify-content: space-between;

    h5 {
      margin-top: 13px;
      font-weight: 700;
      font-size: 19px;
    }
  }

  .profile-bar {
    display: flex;
  }
}

.mview {
  height: 90px;
}

.sider-icon-collapse {
  height: 30px;
  position: absolute;
  right: -11px;
  top: 40px;
  background: #F5F5F5;
  border-radius: 13px;
}

.sider-divider-vertical {
  height: 100%;
  padding: 0;
  margin-inline: 4px;
  background-color: #A9A9A9;
  float: right;
  width: 3px;
}

.ant-layout-sider {
  background-color: white !important;
  width: 350px !important;
  min-width: 250px !important;
  max-width: 350px !important;

  &.ant-layout-sider-collapsed {
    min-width: 40px !important;
    width: 40px !important;
    flex: 0 0 40px !important;
    background: #F5F5F5 !important;
  }

  .ant-layout-sider-children {
    overflow: auto;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
  }

  .ant-layout-sider-children::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
}

.desktop-layout {
  background-color: #ebecee;
  height: 100vh;

  .left-nav {
    background-color: gray;
    height: 100%;
    width: 250px;
    position: fixed;
  }

  .content-wrapper {
    // background-color: white;
    // padding: 15px 25px 0;
    height: 100vh;
    padding: 20px;
    overflow: auto;
  }
}