.doc-mobile-items-header {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
        display: grid;

        .doc-category-tags {
            margin: 0px;
            margin-right: 10px;
        }
    }
}

.doc-collapse {
    margin-top: 20px;
    background-color: #fff;
    border: solid 1px #bfc4e1;
    border-radius: 6px;

    .ant-collapse-item {
        border-bottom: none;

        .ant-collapse-header {
            width: 100%;
            min-height: 44px;

            .ant-collapse-header-text {
                width: 100%;
            }
        }

        .ant-collapse-content-box {
            padding: 0px;

            .ant-row {
                padding-block: 10px;
                border-bottom: 1px solid #bfc4e1;

                .ant-form-item-row {
                    border: none;

                    // border-radius: 11px;
                    .custom-input {
                        margin: 5px;
                        padding: 5px;
                        border: 1px solid #bfc4e1;
                        border-radius: 11px;
                    }
                }

                .action-btns {
                    margin-right: 5px;
                    overflow: hidden;

                    .ant-btn {
                        background-color: transparent;
                    }
                }

                .ant-btn .button-submit {
                    color: #ffffff;
                    background-color: #273896;
                    border-color: #273896;
                }

                .custom-select .ant-select-selector {
                    border-radius: 11px !important;
                }

                // .ant-btn {
                //   background-color: transparent;
                // }
            }

            .error {
                background-color: rgba(225, 33, 33, 0.14);
            }
        }
    }
}

.ant-select-selection-item {
    & .downloadBtn {
        display: none;
    }
}

.ant-select-selection-item {
    color: black;
}

.save-btn {
    width: 100px !important;
}

.doc-category-tags {
    --tagBgColor: #3991F6;
    background-color: var(--tagBgColor);
    border-radius: 3px;
    color: white;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    height: 26px !important;
    letter-spacing: .2px;
    line-height: 141%;
    margin: 0 5px;
    padding: 2px;
    text-align: center;
    width: 118px !important;
}