.submit-excel{
    color: #253f87;
    .ant-btn-icon{
        svg{
           g{
            path{
                fill: #253f87 !important;
            }
           }
        }
    }
}

.footer-table{
    display: flex;
    justify-content: space-between;
    height: 30px;
    align-items: center;
    padding-inline: 2rem;
}

.disbursal-details{
    height: 90px;
    width: 162px;
    border: 1px solid #0000004D;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.lender-details{
    width: 357px;
    border: 1px solid #F0F0F0;
    border-radius: 8px;
    background: #F8F9FB;
    padding: 15px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}