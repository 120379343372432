.loader-view {
    position: absolute;
    inset: 0;
    z-index: 5;
    background-color: black;
    opacity: 0.7;
    display: grid;
    align-content: center;
    .ant-spin {
      .ant-spin-text {
        font-size: 20px !important;
        margin-top: 10px;
        text-shadow: none !important;
      }
    }
  }
  