.iframe-pdf-view {
    width: 100%;
    height: 350px;
    display: block;
}

.img-address {
    width: 100%;
    display: block;
    height: 350px;
}

.iframe-doc-view{
    width: 100%;
    justify-content: center;
    align-items:center;
    height: 350px;
    padding-top: 30%;
}

.close-btn{
    width: 150px;
    height: 40px !important;
    float: right;
}