.fields-width {
    // .ant-space-item {
    width: -webkit-fill-available !important;
    // }
}

.upload-document-modal {
    display: flex;
    gap: 20px;
    .upload-btn-submit {
        margin-top: 35px;
    }
    .ant-form-item {
        margin-bottom: 0px;
    }
}

.upload-wrapper{
    width: 350px;
    .ant-upload-select{
        width: 100%;
        height: 76px;
        margin: 14px 0px;
        svg{
            margin-right: 10px;
        }
        span{
            display: flex;
            width: 100%;
            height: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;
            border: dashed 1px #273896;
            cursor: pointer;
        }
    }
    .ant-upload-list{
        padding-bottom: 15px;
        .ant-upload-list-item{
            margin-bottom: 10px;
        }
    }
}

.paste-error-table {
    .field-bg {
        margin: 0px;
        padding: 0px;
    }
}