@import "../../shared/styles/Variables.scss";

.assign-tabs {
  .ant-tabs-nav {
    margin: 0;
    margin-top: 20px;

    .ant-tabs-nav-wrap {
      background-color: rgba(235, 238, 243, 1);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .ant-tabs-tab {
        padding-inline: 16px;
      }

      .ant-tabs-tab+.ant-tabs-tab {
        margin: 0 0 0 16px;
      }
    }
  }
}

.custom-auto-compleat {
  width: 250px !important;
  padding-right: 10px;

  .ant-select-selector {
    height: 40px !important;
    border: 1px solid $color-border !important;

    input {
      height: 38px !important;
      background: url('../../assets/images/search-auto-com.svg') no-repeat right center !important;
      background-size: 20px 20px !important;
      padding-right: 25px !important;
      color: black;
    }

    .ant-select-selection-placeholder {
      line-height: 37px;
    }
  }
}

.assign-title {
  background-color: transparent !important;
}