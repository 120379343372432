.App {
  text-align: center;
}

.gap-20 {
  gap: 20px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-20 {
  margin-top: 20px;
}

.p-0 {
  padding: 0px;
}

.p-15 {
  padding: 15px;
}

.content-sb {
  display: flex;
  justify-content: space-between;
}

// for hide numaric text from form io file
.sr-only {
  display: none;
}

.layout-main-top {
  display: flex;
  margin-top: 20px;
  background-color: #EBEEF3;
  height: 60%;
  min-height: 60px;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 2px solid rgba(204, 204, 204, 0.395);
  padding-inline: 15px;
  gap: 8px;

  .search-input {
    width: 250px !important;
    // width: 100% !important;
    border-radius: 12px !important;
    height: 42px;

    @media(max-width: 650px) {
      margin-bottom: 15px;
    }
  }


  @media(max-width: 650px) {
    display: grid !important;
  }
}

.layout-main {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  height: 100%;
  min-height: 73vh;
  width: 100%;
}

// Hide arrow inside number field
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.dynamic-pagination-checkbox {
  .ant-checkbox {

    &-wrapper:hover .ant-checkbox,
    &:hover,
    &-focused {
      .ant-checkbox-inner {
        border-color: #20A74E;
      }
    }

    &-inner {
      border: 1px solid #20A74E;
      background-color: white;
    }

    &-checked,
    &-indeterminate {
      .ant-checkbox-inner {
        background-color: #20A74E;
        border-color: #20A74E;
      }
    }
  }

.ant-table-footer {
    background: #ebeef363 !important;
  }

}