.btn-common {
    width: 100%;
    // margin-top: 2%;

    border-radius: 8px;
    padding-inline: 25px !important;

    svg {
        margin-left: -5px !important;
        margin: -1px;
    }
}

.svg-icon {
    svg {
        margin-right: 5px !important;
    }
}

.ant-btn-default {
    color: #C71C8E !important;
    border-color: #C71C8E !important;

    &:hover {
        background: #f2f2f2;
    }
}